import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '../../../../environments/api_url';
import { Router } from '@angular/router';
import { OrdersService } from '../orders/orders.service';
import { ElectronService } from '../electron/electron.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http : HttpClient,
    private _router : Router,
    private _OrdersService : OrdersService,
    private _ElectronService : ElectronService,

  ) { }

  getNotificationList(payload:any){
    payload.per_page = 10
    const url = api.URL + '/api/notification-list'
    
    return this.http.post(url, payload)
  }


  setReadNotification(notificationId:number){
    let payload= {notification_id: notificationId, is_read:1};
    const url = api.URL + '/api/notification/update-status';    
    return this.http.post(url, payload);
  }
  setAllReadNotification(){
    let payload= {badge_read:1};
    const url = api.URL + '/api/notification/badge-read';    
    return this.http.post(url, payload);
  }


  notificationEventHandle(data:any){
    // console.log(data);
    console.log(data, '11', 'test data type');
    
    if (!data) {
      return;
    }
    
    switch (data.type) {
      case 1:
        // redirect to order listing page
        // console.log(data);
        
        this._router.navigate([`merchants/dashboard/order-request-detail/${data?.data?.order_id}`])
        break;
      case 2:
        // Handle ORDER_TIMELINE_UPDATE if needed
        let payload = {
          id:''
        }
        this._router.navigate([`merchants/orders/details/${data?.data?.id}`])
        this._OrdersService.getOrders(payload)
        break;
      case 3:
        // ORDER ASSIGNED
        this._router.navigate([`merchants/orders/details/${data?.data?.id}`])
        break;
      case 4:
        // banner Messange Change for vendor
        this._router.navigate([`merchants/update-profile`])
        break;
      case 5:
        this._router.navigate([`merchants/orders/details/${data?.data?.id}`])
        break;
      default:
        console.log('Unknown event type', data.type);
    }
  }
}



